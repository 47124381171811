import styled from 'styled-components';
import { darkTheme, generalTheme, ThemePropsDixi } from '../../../theme/theme';

export const Container = styled('div')`
  display: inline-block;
  width: 100%;
  padding: 10px 5px;
  margin-bottom: -0.625rem;
`;

export const TextArea = styled('textarea')`
  resize: none;
  font-size: 0.875rem;
  height: 185px;
  color: ${(props: ThemePropsDixi) => props.theme.textColor};
  border: 1px solid ${generalTheme.primaryColor};
  background-color: ${darkTheme.backgroundColor};

  &:focus {
    background-color: ${darkTheme.backgroundColor};
    box-shadow: 0 0 15px 0 ${generalTheme.primaryColor};
  }
`;

export const Label = styled('label')`
  color: ${(props: ThemePropsDixi) => props.theme.textColor};
  display: block;
  padding-bottom: 10px;
  text-transform: capitalize;
`;
