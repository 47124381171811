import styled from 'styled-components';

// noinspection CssReplaceWithShorthandSafely
export const Container = styled('div')`
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 0.3s;
  height: 420px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  background-color: #1d0046;
  border: 2px solid #743ad5;
  border-radius: 10px;
  position: relative;

  @media only screen and (max-width: 990px) {
    width: 70vw;
    margin: 15px auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.4);
    transform: scale(1.1);
  }

  &:after {
    border-radius: 50px
  }
`;

export const Title = styled('h2')`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.18;
  text-align: center;
`;
