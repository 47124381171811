import { ButtonProps } from '../../../@types/types';
import * as Styled from './Button.Styled';

export const Button = ({
    color,
    fixedWidth,
    children,
    onClick,
    type,
}: ButtonProps) => (
<Styled.Button type={type}
               color={color}
               fixedWidth={fixedWidth}
               onClick={onClick} >
    {children}
</Styled.Button >
);
