import { Col, Row } from 'antd';
import i18n from 'i18next';
import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Lang, Theme } from '../../../@types/types';
import israel from '../../../assets/israel.svg';
import usa from '../../../assets/united-states.svg';
import { changeLangAction, changeThemeAction } from '../../../store/actions/preferencesActions';
import { Button } from '../../common/button/Button';
import * as Styled from './VerticalMenu.Styled';

interface IProps {
    changeLangAction: any,
    changeThemeAction: any,
    lang: Lang,
    lottieRef: any
    scrollTo: (id: string) => void
    t: any
    theme: Theme,
}

const VerticalMenu: FC<IProps> = ({ t, lang, changeLangAction, scrollTo }) => {
    return (
    <Row gutter={[0, 20]}
         align='middle'
         style={{
             textAlign: lang === 'he'
                        ? 'right'
                        : 'left',
         }} >
        
        {/*language*/}
        <Col span={24} >
            <Row gutter={20} >
                <Col >
                    <Styled.LanguageSwitch onClick={() => {
                        changeLangAction('en');
                        i18n.changeLanguage('en');
                    }} >
                        <img src={usa}
                             aria-label='homepage'
                             width='30px'
                             height='30px'
                             alt={'usa'} />
                    </Styled.LanguageSwitch >
                </Col >
                
                <Col >
                    <Styled.LanguageSwitch onClick={() => {
                        changeLangAction('he');
                        i18n.changeLanguage('he');
                    }} >
                        <img src={israel}
                             aria-label='homepage'
                             width='30px'
                             height='30px'
                             alt={'israel'} />
                    </Styled.LanguageSwitch >
                </Col >
            </Row >
        </Col >
        
        {/*menu*/}
        <Col span={24}
             onClick={() => scrollTo('home')} >
            <Styled.Item >{t('HeaderHome')}</Styled.Item >
        </Col >
        
        <Col span={24}
             onClick={() => scrollTo('features')} >
            <Styled.Item >{t('HeaderFeatures')}</Styled.Item >
        </Col >
        
        <Col span={24}
             onClick={() => scrollTo('customers')} >
            <Styled.Item >{t('HeaderCustomers')}</Styled.Item >
        </Col >
        
        <Col span={24}
             onClick={() => scrollTo('about')} >
            <Styled.Item >{t('HeaderAbout')}</Styled.Item >
        </Col >
        
        <Col span={24}
             onClick={() => scrollTo('faq')} >
            <Styled.Item >{t('HeaderFaq')}</Styled.Item >
        </Col >
        
        {/*contact*/}
        <Col span={24}
             onClick={() => scrollTo('contact')} >
            <Button >{t('HeaderContactButton')}</Button >
        </Col >
    </Row >
    );
};

const mapStateToProps = (state: any) => {
    return {
        theme: state.preferences.theme,
        lang:  state.preferences.lang,
    };
};

const mapDispatchToProps = {
    changeLangAction,
    changeThemeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VerticalMenu));
