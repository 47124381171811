import styled from 'styled-components';
import { darkTheme, generalTheme, ThemePropsDixi } from '../../../theme/theme';

export const Container = styled('div')`
  display: inline-block;
  width: 100%;
  padding: 10px 5px;
`;

export const Input = styled('input')`
  color: ${(props: ThemePropsDixi) => props.theme.textColor};
  font-size: 0.875rem;
  background-color: ${darkTheme.backgroundColor};
  border: 1px solid ${generalTheme.primaryColor};

  &:focus {
    background-color: ${darkTheme.backgroundColor};
    box-shadow: 0 0 15px 0 ${generalTheme.primaryColor};
  }
`;
