import styled from 'styled-components';
import { generalTheme, ThemePropsDixi } from '../../../theme/theme';

export const Button = styled('button')<any>`
  background: ${(props) => props.color || generalTheme.primaryColor};
  color: ${(props) => (props.color
          ? generalTheme.primaryColor
          : (props: ThemePropsDixi) => props.theme.accentColor)};
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  border: 1px solid ${generalTheme.primaryColor};
  border-radius: 5px;
  padding: 13px 0;
  cursor: pointer;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 30px ${generalTheme.primaryColor};


  &:hover {
    box-shadow: 0 0 20px 0 ${generalTheme.primaryColor};
  }
`;
