import { Col, notification, Row } from 'antd';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { Zoom } from 'react-awesome-reveal';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// @ts-ignore
import {
    LoadCanvasTemplate,
    loadCaptchaEnginge,
    validateCaptcha,
    // @ts-ignore
} from 'react-simple-captcha';
import { Lang } from '../../@types/types';
import contact from '../../assets/contact-us.svg';
import { Button } from '../common/button/Button';
import Input from '../common/input/Input';
import TextArea from '../common/text-area/TextArea';
import * as GlobalStyled from '../styled/Global.styled';
import { imgPosAndShadow, SectionContainer } from '../styled/Global.styled';
import * as Styled from './Contact.Styled';
import { CaptchaInput } from './Contact.Styled';

interface IProps {
    lang: Lang;
    t: any;
}

const Contact: FC<IProps> = ({ t, lang }) => {
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');
    const [sendDisabled, setSendDisabled] = useState(false);

    function openErrorNotification(description: string) {
        notification['error']({
            message: t('ContactNotificationMessage'),
            description: t(description),
        });
    }

    function openSuccessNotification() {
        notification['success']({
            message: t('ContactNotificationMessage'),
            description: t('ContactNotificationDescription'),
        });
    }

    function handleSubmit(event: React.ChangeEvent<HTMLFormElement>) {
        event.preventDefault();

        if (sendDisabled) {
            return;
        }

        if (!validate()) {
            return;
        }

        if (!isCaptchaValid) {
            openErrorNotification(t('ContactCapchaError'));
            return;
        }

        var submitButton = document.querySelector('button[type="submit"]');

        if (submitButton) {
            // @ts-ignore
            submitButton.disabled = true;
            // @ts-ignore
            submitButton.style.backgroundColor = '#59446c';

            setSendDisabled(true);
        }

        axios
            .post('/email', { name, email, message }, {})
            .then((value) => {
                openSuccessNotification();
            })
            .catch((reason) => {
                openErrorNotification(reason.message);
            });
    }

    useEffect(() => {
        loadCaptchaEnginge(6, '#1B1C22', '#9D37FC');
    }, []);

    function validate() {
        let valid = true;

        if (!name) {
            setNameError('Name is required');
            valid = false;
        } else {
            setNameError('');
            // setName('');
        }

        if (email && !/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Email address is invalid');
            valid = false;
        } else if (!email) {
            setEmailError('Email is required');
            valid = false;
        } else {
            setEmailError('');
            // setEmail('');
        }

        if (!message) {
            setMessageError('Message is required');
            valid = false;
        } else {
            setMessageError('');
            // setMessage('');
        }

        return valid;
    }

    const ValidationType = ({ error }: any) => {
        return (
            // <Zoom direction='left'>
            <Styled.Span>{error}</Styled.Span>
            // </Zoom>
        );
    };

    return (
        // MAIN CONTACT US SECTION
        <SectionContainer
            id={'contact'}
            lang={lang}
            style={
                {
                    // backgroundColor: 'red',
                }
            }
        >
            <Row justify='space-between' align='middle'>
                {/*IMAGE SECTION*/}
                <Col
                    lg={12}
                    md={24}
                    sm={24}
                    xs={24}
                    style={
                        {
                            // backgroundColor: 'yellow',
                        }
                    }
                >
                    <GlobalStyled.SectionTitleCenter>
                        {t('ContactTitle')}
                    </GlobalStyled.SectionTitleCenter>
                    <GlobalStyled.SectionTextCenter style={{ width: '65%' }}>
                        {t('ContactText')}
                    </GlobalStyled.SectionTextCenter>
                    <img
                        src={contact}
                        width={'50%'}
                        alt='test'
                        style={{ ...imgPosAndShadow, ...{ marginTop: 20 } }}
                    />
                </Col>
                {/*INPUT SECTION*/}
                <Col
                    lg={12}
                    md={24}
                    sm={24}
                    xs={24}
                    style={
                        {
                            // backgroundColor: 'blue',
                        }
                    }
                >
                    <Styled.FormGroup
                        autoComplete='off'
                        onSubmit={handleSubmit}
                    >
                        {/*ENTER SUBJECT*/}
                        <Col
                            span={24}
                            style={
                                {
                                    // backgroundColor: 'green',
                                }
                            }
                        >
                            <Input
                                id='name'
                                type='text'
                                name={t('ContactFormLabelName')}
                                placeholder={t('ContactFormInputName')}
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value);
                                    nameError && setNameError('');
                                }}
                            />

                            {nameError && <ValidationType error={nameError} />}
                        </Col>
                        {/*ENTER MAIL TO RETURN*/}
                        <Col
                            span={24}
                            style={
                                {
                                    // backgroundColor: 'brown',
                                }
                            }
                        >
                            <Input
                                id='email'
                                type='text'
                                name={t('ContactFormLabelEmail')}
                                placeholder={t('ContactFormInputEmail')}
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                    emailError && setEmailError('');
                                }}
                            />
                            {emailError && (
                                <ValidationType error={emailError} />
                            )}
                        </Col>
                        {/*ENTER CONTENT*/}
                        <Col
                            span={24}
                            style={
                                {
                                    // backgroundColor: 'purple',
                                }
                            }
                        >
                            <TextArea
                                id='message'
                                placeholder={t('ContactFormInputMessage')}
                                value={message}
                                name={t('ContactFormLabelMessage')}
                                onChange={(event) => {
                                    setMessage(event.target.value);
                                    messageError && setMessageError('');
                                }}
                            />
                            {messageError && (
                                <ValidationType error={messageError} />
                            )}
                        </Col>
                        {/*KAPTCHA SECTION*/}
                        <Row
                            style={{
                                padding: 5,
                                marginTop: 10,
                                // backgroundColor: 'salmon',
                            }}
                            gutter={10}
                        >
                            <Col>
                                <LoadCanvasTemplate
                                    reloadText={
                                        lang === 'he' ? 'טען מחדש' : 'Reload'
                                    }
                                    reloadColor='#ccc'
                                />
                            </Col>

                            <Col>
                                <CaptchaInput
                                    placeholder={
                                        lang === 'he'
                                            ? 'אימות...'
                                            : 'captcha...'
                                    }
                                    id={'user_captcha_input'}
                                />
                            </Col>
                        </Row>

                        <Styled.ButtonContainer
                            style={
                                {
                                    // backgroundColor: 'seagreen',
                                }
                            }
                        >
                            <Button
                                type={'submit'}
                                onClick={() => {
                                    let user_captcha_value = // @ts-ignore
                                        document.getElementById(
                                            'user_captcha_input',
                                            // @ts-ignore
                                        )?.value;

                                    if (
                                        validateCaptcha(user_captcha_value) ===
                                        true
                                    ) {
                                        loadCaptchaEnginge(
                                            6,
                                            '#1B1C22',
                                            '#B367FF',
                                        );
                                        setIsCaptchaValid(true);
                                        // @ts-ignore
                                        document.getElementById(
                                            'user_captcha_input',
                                            // @ts-ignore
                                        ).value = '';
                                    } else {
                                        // alert('אימות אינו תקין');
                                        setIsCaptchaValid(false);
                                        return;
                                    }
                                }}
                                name='submit'
                            >
                                {t('ContactSubmitButton')}
                            </Button>
                        </Styled.ButtonContainer>
                    </Styled.FormGroup>
                </Col>
            </Row>
        </SectionContainer>
    );
};

const mapStateToProps = (state: any) => {
    return {
        theme: state.preferences.theme,
        lang: state.preferences.lang,
    };
};

export default connect(mapStateToProps)(withTranslation()(Contact));
