import { CSSProperties } from 'react';
import styled from 'styled-components';
import { generalTheme } from '../../theme/theme';

export const FontFamily = "'Rubik', sans-serif";
export const FadeDuration = 2500;
export const LottieFeatureWidth = 200;
export const ColSpanLg = 12;

export const SectionTitleCenter = styled('div')`
    font-weight: 700;
    //color: ${(props) => props.theme.titleTextColor};
    font-size: 56px;
    line-height: 1.18;
    text-align: center;
    margin-bottom: 28px;
    background: linear-gradient(45deg, #743ad5, #d53a9d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    //@media only screen and (max-width: 890px) {
    //  font-size: 47px;
    //}
    //
    //@media only screen and (max-width: 414px) {
    //  font-size: 32px;
    //}
`;

export const SectionTitleAligned = styled('div')`
    font-weight: 700;
    //color: ${(props) => props.theme.titleTextColor};
    font-size: 56px;
    line-height: 1.18;
    margin-bottom: 28px;
    background: linear-gradient(45deg, #743ad5, #d53a9d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    //text-align: center;

    @media only screen and (max-width: 990px) {
        text-align: center;
    }
    //
    //@media only screen and (max-width: 414px) {
    //  font-size: 32px;
    //}
`;

export const SectionTextCenter = styled('p')`
    color: ${(props) => props.theme.textColor};
    font-size: 18px;
    line-height: 1.41;
    text-align: center;
    margin: 0 auto;
`;

export const SectionTextAligned = styled('p')`
    color: ${(props) => props.theme.textColor};
    font-size: 18px;
    line-height: 1.41;

    @media only screen and (max-width: 990px) {
        text-align: center;
    }
`;

export const SectionContainer = styled('div')`
    width: 100%;
    margin-bottom: 100px;
    padding: 0 200px;

    @media only screen and (max-width: 990px) {
        padding: 0 50px;
    }
`;

export const imgPosAndShadow: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    filter: `drop-shadow(0 0 0.75rem ${generalTheme.primaryColor})`,
};
