import { Col, Drawer, Row } from 'antd';
import { LottieRefCurrentProps } from 'lottie-react';
import React, { FC, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Lang, Theme } from '../../@types/types';
import { darkTheme, lightTheme } from '../../theme/theme';
import HorizontalMenu from '../menu/horizontal-menu/HorizontalMenu';
import VerticalMenu from '../menu/vertical-menu/VerticalMenu';
import * as GlobalStyled from '../styled/Global.styled';
import * as Styled from './Header.Styled';

interface IProps {
    lang?: Lang
    t: any
    theme?: Theme,
}

const Header: FC<IProps> = ({ t, theme, lang }) => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    
    const lottieRef = useRef<LottieRefCurrentProps>();
    
    const showDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };
    
    const onClose = () => {
        setDrawerVisible(!drawerVisible);
    };
    
    const scrollTo = (id: string) => {
        const element = document.getElementById(id) as HTMLDivElement;
        element.scrollIntoView({
            behavior: 'smooth',
            block:    'center',
        });
        setDrawerVisible(false);
    };
    
    return (
    <GlobalStyled.SectionContainer style={{ height: '100px', marginBottom: 50 }} >
        <Row >
            {/*menu*/}
            <Col lg={24}
                 md={0}
                 sm={0}
                 xs={0} >
                <HorizontalMenu scrollTo={scrollTo}
                                lottieRef={lottieRef} />
            </Col >
            
            {/*burger icon*/}
            <Row align='middle'
                 style={{ height: '80px' }} >
                <Col lg={0}
                     md={24}
                     sm={24}
                     xs={24}
                     onClick={showDrawer} >
                    <Styled.Burger />
                </Col >
            </Row >
        </Row >
        
        {/*drawer*/}
        <Drawer placement={lang === 'he'
        ? 'right'
        : 'left'}
                closable={false}
                visible={drawerVisible}
                onClose={onClose}
                bodyStyle={{
                    backgroundColor: theme === 'light'
                                     ? lightTheme.backgroundColor
                                     : darkTheme.backgroundColor,
                    paddingTop:      '0',
                    paddingRight:    '50px',
                }} >
            
            <Row align='middle'
                 onClick={onClose}
                 style={{ height: '80px' }} >
                {/*burger icon*/}
                <Col span={2} >
                    <Styled.Burger />
                </Col >
                
                {/*title*/}
                <Col span={22}
                     style={{ textAlign: 'center' }} >
                    <Styled.Menu >{t('HeaderMenuTitle')}</Styled.Menu >
                </Col >
            </Row >
            
            {/*burger menu*/}
            <VerticalMenu scrollTo={scrollTo}
                          lottieRef={lottieRef} />
        </Drawer >
    </GlobalStyled.SectionContainer >
    );
};

const mapStateToProps = (state: any) => {
    return {
        theme: state.preferences.theme,
        lang:  state.preferences.lang,
    };
};

export default connect(mapStateToProps)(withTranslation()(Header));
