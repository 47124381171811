import { ThemeProps } from 'styled-components';

export const lightTheme: DixiTheme = {
    backgroundColor:  '#FFFFFF',
    accentColor:      '#EDF2F4',
    highlightColor:   '#3C50FA',
    primaryColor:     '#185ADB',
    textColor:        '#8D99AE',
    footerText:       '#100F0F',
    footerBackground: '#CCCCCC',
    titleTextColor:   '#185ADB',
    headerTextColor:  '#262626',
    shadowColor:      '#5968FC',
    faqQuestion:      ' linear-gradient(to right, #0acffe 0%, #495aff 100%',
    faqAnswer:        'linear-gradient(120deg, #f093fb 0%, #f5576c 100%)',
};

export const darkTheme: DixiTheme = Object.freeze({
    backgroundColor:  '#1B1C22',
    accentColor:      '#E0FBFC',
    highlightColor:   '#3C50FA',
    primaryColor:     '#3C50FA',
    headerTextColor:  '#CCCCCC',
    textColor:        '#8D99AE',
    footerBackground: '#0F0F10',
    footerText:       '#C7C7C8',
    titleTextColor:   '#CCCCCC',
    shadowColor:      '#5968FC',
    faqQuestion:      'linear-gradient(to right, #434343 0%, black 100%)',
    faqAnswer:        '#C7C7C8',
});

export interface DixiTheme {
    accentColor?: string;
    backgroundColor?: string;
    faqAnswer?: string;
    faqQuestion?: string;
    footerBackground?: string;
    footerText?: string;
    headerTextColor?: string;
    highlightColor?: string;
    primaryColor?: string;
    shadowColor?: string;
    textColor?: string;
    titleTextColor?: string;
}

export const generalTheme = {
    primaryColor:            '#622993',
    backgroundColor:         '#1B1C22',
    textColor:               '#8D99AE',
    productDescriptionColor: '#6E6E6E',
    productTitleColor:       '#CCCCCC',
};

export type ThemePropsDixi = ThemeProps<DixiTheme>;
