import 'antd/dist/antd.css';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './components/app/App';
import './fonts/Rubik-VariableFont_wght.ttf';
import store from './store/store';

import './styles/style.scss';
import i18n from './translation';

ReactDOM.render(
<Provider store={store} >
    <BrowserRouter >
        <I18nextProvider i18n={i18n} >
            <App />
        </I18nextProvider >
    </BrowserRouter >
</Provider >, document.getElementById('root'));
