import styled from 'styled-components';
import { darkTheme, generalTheme, ThemePropsDixi } from '../../theme/theme';

export const FormGroup = styled('form')`
  width: 100%;
  max-width: 520px;

  @media only screen and (max-width: 1045px) {
    max-width: 100%;
    margin-top: 2rem;
  }
`;

export const Span = styled('span')<any>`
  display: block;
  font-weight: 600;
  color: rgb(255, 130, 92);
  height: 0.775rem;
  padding: 0 0.675rem;
`;

export const ButtonContainer = styled('div')`
  text-align: end;
  position: relative;

  @media only screen and (max-width: 414px) {
    padding-top: 0.75rem;
  }
`;

export const CaptchaInput = styled('input')`
  color: ${(props: ThemePropsDixi) => props.theme.textColor};
  font-size: 16px;
  height: 18px;
  width: 114px;
  transform: translateY(-2px);
  background-color: ${darkTheme.backgroundColor};
  border: 1px solid ${generalTheme.primaryColor};

  &:focus {
    background-color: ${darkTheme.backgroundColor};
    box-shadow: 0 0 15px 0 ${generalTheme.primaryColor};
  }
`;
