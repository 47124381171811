import asafharofe from '../../assets/custormers/asafharofe.png';
import ashdod from '../../assets/custormers/ashdod.png';
import assuta from '../../assets/custormers/assuta.png';
import barzili from '../../assets/custormers/barzili.png';
import bneiZion from '../../assets/custormers/bneiZion.png';
import clalit from '../../assets/custormers/clalit.png';
import dmsMar from '../../assets/custormers/dmsMar.png';
import ehilov from '../../assets/custormers/ehilov.png';
import ergocom from '../../assets/custormers/ergocom.png';
import etype from '../../assets/custormers/etype.png';
import galil from '../../assets/custormers/galil.png';
import hadasah from '../../assets/custormers/hadasa.png';
import HaganatHaPratiut from '../../assets/custormers/HaganatHaPratiut.png';
import hapoalim from '../../assets/custormers/hapoalim.png';
import heleYaffe from '../../assets/custormers/helel-yaffe.png';
import herzilia from '../../assets/custormers/herzilia.png';
import hever from '../../assets/custormers/hever.png';
import knesset from '../../assets/custormers/knesset.png';
import laniado from '../../assets/custormers/laniado.png';
import mackabi from '../../assets/custormers/mackabi.png';
import mayaneiYehusha from '../../assets/custormers/mayanie-yehusha.png';
import telAviv from '../../assets/custormers/med-tel-aviv.png';
import meuhedet from '../../assets/custormers/meuhedet.png';
import neyarotEreh from '../../assets/custormers/neyarot-ereh.png';
import poriya from '../../assets/custormers/poriya.png';
import rambam from '../../assets/custormers/rambam.png';
import sheariZedek from '../../assets/custormers/sheari-zedek.png';
import shiba from '../../assets/custormers/shiba.png';
import shidur from '../../assets/custormers/shidur.png';
import shnieder from '../../assets/custormers/shnieder.png';
import taharut from '../../assets/custormers/taharut.png';
import tikshuv from '../../assets/custormers/tikshuv.png';
import wallpson from '../../assets/custormers/wallpson.png';
import ynet from '../../assets/custormers/ynet.png';
import ziv from '../../assets/custormers/ziv.png';

type IClient = { name: string, image: string, }

export const clients: IClient[] = [
    { name: 'אסף הרופא', image: hapoalim },
    { name: 'אסף הרופא', image: bneiZion },
    { name: 'אסף הרופא', image: ynet },
    { name: 'אסף הרופא', image: asafharofe },
    { name: 'אסף הרופא', image: ehilov },
    { name: 'אסף הרופא', image: hever },
    { name: 'מכבי', image: mackabi },
    { name: 'וולפסון', image: wallpson },
    { name: 'וולפסון', image: knesset },
    { name: 'כללית', image: clalit },
    { name: 'שניידר', image: shnieder },
    { name: 'שניידר', image: taharut },
    { name: 'אסף הרופא', image: telAviv },
    { name: 'אסף הרופא', image: barzili },
    { name: 'אסף הרופא', image: shiba },
    { name: 'אסף הרופא', image: neyarotEreh },
    { name: 'אסף הרופא', image: sheariZedek },
    { name: 'אסף הרופא', image: shidur },
    { name: 'אסף הרופא', image: hadasah },
    { name: 'אסף הרופא', image: tikshuv },
    { name: 'אסף הרופא', image: ziv },
    { name: 'אסף הרופא', image: HaganatHaPratiut },
    { name: 'אסף הרופא', image: ashdod },
    { name: 'אסף הרופא', image: rambam },
    { name: 'אסף הרופא', image: ergocom },
    { name: 'אסף הרופא', image: meuhedet },
    { name: 'אסף הרופא', image: heleYaffe },
    { name: 'אסף הרופא', image: dmsMar },
    { name: 'אסף הרופא', image: herzilia },
    { name: 'אסף הרופא', image: mayaneiYehusha },
    { name: 'אסף הרופא', image: poriya },
    { name: 'אסף הרופא', image: galil },
    { name: 'אסף הרופא', image: etype },
    { name: 'אסף הרופא', image: assuta },
    { name: 'אסף הרופא', image: laniado },
];
