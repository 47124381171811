import { Row } from 'antd';
import React, { CSSProperties, FC } from 'react';
import { CgScrollV } from 'react-icons/cg';
import Lottie from 'react-lottie';
import { generalTheme } from '../../../theme/theme';
import { FeatureData } from './FeatureData.styled';
import { FeatureImage } from './FeatureImage.styled';
import * as Styled from './FeatureItem.Styled';

interface IProps {
    image?: string;
    imageStyle?: CSSProperties;
    lottie?: any;
    text: string;
    title: string;
}

const LottieFeatureWidth = 200;

const FeatureItem: FC<IProps> = ({ title, text, lottie, image, imageStyle }) => {
    return (
    <Styled.Container >
        <Row justify={'center'} >
            <CgScrollV size={32}
                       color={'#CCCCCC'}
                       style={{ position: 'absolute', left: 0, marginLeft: 5, marginTop: 10 }} />
            
            {lottie
            ? <Lottie width={LottieFeatureWidth}
                      options={{ animationData: lottie }} />
            : <FeatureImage src={image}
                            alt={title}
                            style={{ ...img, ...imageStyle }} />}
        </Row >
        
        <FeatureData >
            <Styled.Title >{title}</Styled.Title >
            {text}
        </FeatureData >
    </Styled.Container >
    );
};

export default FeatureItem;

const img: CSSProperties = {
    filter: `drop-shadow(0 0 0.75rem ${generalTheme.primaryColor})`,
};
