import styled from 'styled-components';
import { ThemePropsDixi } from '../../theme/theme';

export const Extra = styled('section')`
    background: ${(props: ThemePropsDixi) => props.theme.footerBackground};
    position: absolute;
    right: 0;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 3rem;
    padding-right: 80px;
`;

export const Para = styled('span')`
    color: ${(props: ThemePropsDixi) => props.theme.footerText};
    font-size: 14px;
    width: 70%;
`;

export const Language = styled('h4')`
    font-size: 22px;
    text-transform: capitalize;
    color: ${(props: ThemePropsDixi) => props.theme.footerText};

    @media screen and (max-width: 414px) {
        padding: 1.5rem 0;
    }
`;
