import styled from 'styled-components';

export const FeatureImage = styled('img')`
  display: block;
  margin-top: 15px;
  height: 25vh;

  @media only screen and (max-width: 990px) {
    width: 50vw !important;
    height: 25vh;
    margin-top: 50px;
  }
`;