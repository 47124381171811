import { createGlobalStyle } from 'styled-components';
import * as GlobalStyled from '../components/styled/Global.styled';
import { ThemePropsDixi } from '../theme/theme';
// import back from '../assets/endless-constellation3.svg';

export const GlobalStyle = createGlobalStyle<ThemePropsDixi>`
  body,
  html,
  a {
    font-family: ${GlobalStyled.FontFamily}, sans-serif;
  }

  body {
    margin: 0;
    border: 0;
    outline: 0;
    width: 100%;
  }

  a:hover {
    color: ${(props) => props.theme.accentColor};
  }

  input,
  textarea {
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.textColor};
    background: ${(props) => props.theme.backgroundColor};
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;

    :focus-within {
      background: none;
      box-shadow: ${(props) => props.theme.primaryColor} 0 0 0 1px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${GlobalStyled.FontFamily}, sans-serif;
    color: ${(props) => props.theme.titleTextColor};
  }

  a {
    text-decoration: none;
    outline: none;
    color: ${(props) => props.theme.textColor};

    :hover {
      color: ${(props) => props.theme.textColor};;
    }
  }

  *:focus {
    outline: none;
  }

  .about-block-image svg {
    text-align: center;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 1.5rem;
  }

  .ant-drawer-content-wrapper {
    width: 300px !important;
  }
`;
