import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ThemePropsDixi } from '../../../theme/theme';

// noinspection JSUnusedGlobalSymbols
export const LogoContainer = styled(Link)`
  display: flex;
`;

export const CustomNavLink = styled('div')`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const Menu = styled('span')`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
`;

export const Item = styled('span')`
  font-size: 1.2rem;
  color: ${(p: ThemePropsDixi) => p.theme.textColor};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: ${(p: ThemePropsDixi) => p.theme.highlightColor};
    text-underline-position: under;
    text-decoration: ${(p: ThemePropsDixi) => p.theme.highlightColor} wavy underline;
  }
`;

export const LanguageSwitchContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;

export const LanguageSwitch = styled('div')<any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;
