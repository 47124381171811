import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ThemePropsDixi } from '../../theme/theme';

// noinspection JSUnusedGlobalSymbols
export const LogoContainer = styled(Link)`
  display: flex;
`;

export const CustomNavLink = styled('div')`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const Menu = styled('span')`
  color: ${(props: ThemePropsDixi) => props.theme.textColor};
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const Burger = styled(MenuOutlined)<any>`
  color: ${(props: ThemePropsDixi) => props.theme.textColor};
  font-size: 22px;
`;

export const LanguageSwitchContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;
