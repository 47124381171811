import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import analytics from '../../../assets/analytics.svg';
import channelSeparation from '../../../assets/channel-separation.svg';
import dictation from '../../../assets/dictation.svg';
import mrcp from '../../../assets/mrcp.svg';
import multiLang from '../../../assets/multiLang.svg';
import reclamation from '../../../assets/rec-editor.svg';
import secure from '../../../assets/secure.svg';
import speakerSeparation from '../../../assets/speaker-speration.svg';
import * as GlobalStyled from '../../styled/Global.styled';
import { SectionContainer } from '../../styled/Global.styled';
import FeatureItem from '../feature-item/FeatureItem';

interface IProps {
    t: any;
}

const FeatureList: FC<IProps> = ({ t }) => {
    return (
    <SectionContainer id={'features'} >
        <Row >
            <Col lg={24}
                 md={24}
                 sm={24}
                 xs={24} >
                <GlobalStyled.SectionTitleCenter >{t('FeaturesTitle')}</GlobalStyled.SectionTitleCenter >
                <GlobalStyled.SectionTextCenter >{t('FeaturesContent')}</GlobalStyled.SectionTextCenter >
                
                <Row justify='space-between' >
                    <Col lg={6}
                         md={24}
                         sm={24}
                         xs={24} >
                        <FeatureItem title={t('FeaturesBssTitle')}
                                     text={t('FeaturesBssText')}
                                     image={channelSeparation}
                                     imageStyle={{ width: '15vw' }} />
                    </Col >
                    
                    <Col lg={6}
                         md={24}
                         sm={24}
                         xs={24} >
                        <FeatureItem title={t('FeaturesLangsTitle')}
                                     text={t('FeaturesLangsText')}
                                     image={multiLang} />
                    </Col >
                    
                    <Col lg={6}
                         md={24}
                         sm={24}
                         xs={24} >
                        <FeatureItem title={t('FeaturesDiarTitle')}
                                     text={t('FeaturesDiarText')}
                                     image={speakerSeparation}
                                     imageStyle={{ width: '15vw' }} />
                    </Col >
                    
                    <Col lg={6}
                         md={24}
                         sm={24}
                         xs={24} >
                        <FeatureItem title={t('FeaturesDictationTitle')}
                                     text={t('FeaturesDictationText')}
                                     image={dictation}
                                     imageStyle={{ width: '15vw' }} />
                    </Col >
                </Row >
                
                <Row justify='space-between' >
                    <Col lg={6}
                         md={24}
                         sm={24}
                         xs={24} >
                        <FeatureItem title={t('FeaturesSearchTitle')}
                                     text={t('FeaturesSearchText')}
                                     image={analytics} />
                    </Col >
                    
                    <Col lg={6}
                         md={24}
                         sm={24}
                         xs={24} >
                        <FeatureItem title={t('FeaturesOnPremTitle')}
                                     text={t('FeaturesOnPremText')}
                                     image={secure} />
                    </Col >
                    
                    <Col lg={6}
                         md={24}
                         sm={24}
                         xs={24} >
                        <FeatureItem title={t('FeaturesReclamationTitle')}
                                     text={t('FeaturesReclamationText')}
                                     image={reclamation} />
                    </Col >
                    
                    <Col lg={6}
                         md={24}
                         sm={24}
                         xs={24} >
                        <FeatureItem title={t('FeaturesMrcpTitle')}
                                     text={t('FeaturesMrcpText')}
                                     image={mrcp} />
                    </Col >
                </Row >
            </Col >
        </Row >
    </SectionContainer >
    );
};

export default withTranslation()(FeatureList);
