import { withTranslation } from 'react-i18next';
import { InputProps } from '../../../@types/types';
import { Label } from '../text-area/TextArea.Styled';
import * as Styled from './Input.Styled';

const Input = ({ id, name, placeholder, onChange, value, t }: InputProps) => (
<Styled.Container >
    <Label htmlFor={name} >{t(name)}</Label >
    <Styled.Input placeholder={t(placeholder)}
                  id={id}
                  name={name}
                  value={value}
                  onChange={onChange} />
</Styled.Container >
);

export default withTranslation()(Input);
