import styled from 'styled-components';
import background from '../../assets/back.svg';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //noinspection CssUnknownTarget
  background: url(${background}) no-repeat #220939;
  background-size: cover;
`;
