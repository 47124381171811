import { Col, Row } from 'antd';
import { CSSProperties, FC, useEffect, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import { withTranslation } from 'react-i18next';
import { darkTheme, generalTheme } from '../../theme/theme';
import * as GlobalStyled from '../styled/Global.styled';
import { ClientImage } from './ClientImage.styled';
import { clients } from './customers-data';

interface IProps {
    t: any;
}

const Customers: FC<IProps> = ({ t }) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
    }, [width]);

    useEffect(() => {
        const caruselBtns = document.querySelectorAll('.rec.rec-dot');
        caruselBtns.forEach((btn) => {
            btn.setAttribute('aria-label', 'carusel-button');
        });
        console.log({ caruselBtns });
    }, []);

    const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
    };

    const itemWidth = width > 890 ? 170 : 150;
    const itemHeight = width > 890 ? 170 : 150;
    const itemCnt = width > 890 ? 3 : 1;

    const clientsArr = clients.map((client, i) => {
        return (
            <div key={i}>
                <style scoped>{`.ant-image-mask {display: none}`}</style>
                {/*<div style={clientNameStyleObject}>{client.name}</div>*/}
                <ClientImage
                    height={itemHeight}
                    width={itemWidth}
                    src={client.image}
                    alt={'img'}
                />
            </div>
        );
    });

    return (
        <GlobalStyled.SectionContainer
            id={'customers'}
            style={containerStyleObject}
        >
            <Row justify='center' align='middle'>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <GlobalStyled.SectionTitleAligned
                        style={{ textAlign: 'center' }}
                    >
                        {t('CustomersTitle')}
                    </GlobalStyled.SectionTitleAligned>
                    <GlobalStyled.SectionTextAligned
                        style={{ textAlign: 'center' }}
                    >
                        {t('CustomersText')}
                    </GlobalStyled.SectionTextAligned>

                    <Row justify='space-between'>
                        <Col span={24}>
                            <Carousel itemsToShow={itemCnt} isRTL={false}>
                                {clientsArr}
                            </Carousel>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </GlobalStyled.SectionContainer>
    );
};

export default withTranslation()(Customers);

const containerStyleObject: CSSProperties = {
    padding: 30,
    backgroundColor: darkTheme.backgroundColor,
    borderTop: `1px solid ${generalTheme.primaryColor}`,
    borderBottom: `1px solid ${generalTheme.primaryColor}`,
};
