import styled from 'styled-components';

export const FeatureData = styled('div')`
  color: ${(props) => props.theme.textColor};
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  height: 120px;
  padding: 0 10px;


  @media only screen and (max-width: 990px) {
    margin-bottom: 25px;
  }
`;