import { MenuOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ThemePropsDixi } from '../../../theme/theme';

// noinspection JSUnusedGlobalSymbols
export const LogoContainer = styled(Link)`
  display: flex;
`;

export const CustomNavLink = styled('div')`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const Menu = styled('span')`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
`;

export const Item = styled('span')`
  font-size: 1.2rem;
    //color: ${(p: ThemePropsDixi) => p.theme.textColor};
  color: #D5D5D5;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 0 5px;

  //&:before {
  //  position: absolute;
  //  width: 100%;
  //  height: 2px;
  //  left: 0px;
  //  bottom: 0px;
  //  content: '';
  //  background: #FFF;
  //  opacity: 0.3;
  //  transition: all 0.3s;
  //}
  //
  //&:hover:before {
  //  height: 100%
  //}

  // &:hover,
  // &:active,
  // &:focus {
    //   color: ${(p: ThemePropsDixi) => p.theme.highlightColor};
  //   text-underline-position: under;
    //   text-decoration: ${(p: ThemePropsDixi) => p.theme.highlightColor} wavy underline;
  // }
`;

export const LanguageSwitchContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;

export const LanguageSwitch = styled('div')<any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const RowContainer = styled(Row)`
  //background-color: red;
  background: #0000003F;
  box-shadow: 0 1px 5px 0 #d53a9d;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  width: 100%;
  height: 60px;
  position: fixed;
  right: 0;
  z-index: 100;
  padding: 0 30px;
`;
