import React from 'react';
import { connect } from 'react-redux';
import About from '../components/about/About';
import ScrollToTop from '../components/common/scroll-to-top/Scroll';
import Contact from '../components/contact/Contact';
import Customers from '../components/customers/Customers';
import Faq from '../components/faq/Faq';
import FeatureList from '../components/features/feature-list/FeatureList';
import Home from '../components/home/Home';

const Landing = () => {
    
    return (
    <React.Fragment >
        <ScrollToTop />
        <Home />
        <FeatureList />
        <Customers />
        <About />
        <Contact />
        <Faq />
    </React.Fragment >
    );
};

const mapStateToProps = (state: any) => {
    return {
        lang: state.preferences.lang,
    };
};

export default connect(mapStateToProps)(Landing);
