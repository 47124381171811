import styled from 'styled-components';

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;

  @media screen and (min-width: 990px) {
    max-width: 80%;
  }
`;

export const HomeImage = styled('img')`
  width: 450px;

  @media only screen and (max-width: 990px) {
    padding-top: 50px;
    width: 50vw;
  }
`;
