import { Col, Row } from 'antd';
import i18n from 'i18next';
import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Lang, Theme } from '../../../@types/types';
import logo from '../../../assets/dixi-logo-new.svg';
import israel from '../../../assets/israel.svg';
import usa from '../../../assets/united-states.svg';
import { changeLangAction, changeThemeAction } from '../../../store/actions/preferencesActions';
import { generalTheme } from '../../../theme/theme';
import * as Styled from './HorizontalMenu.Styled';

interface IProps {
    changeLangAction: any,
    changeThemeAction: any,
    lang: Lang
    lottieRef: any
    scrollTo: (id: string) => void
    t: any
    theme: Theme,
}

const HorizontalMenu: FC<IProps> = ({ t, changeLangAction, scrollTo, lang }) => {
    return (
    <Styled.RowContainer justify={'center'}
                         align='middle' >
        
        <img onClick={() => scrollTo('home')}
             alt={'logo'}
             height={35}
             src={logo}
             style={{
                 cursor:      'pointer',
                 marginLeft:  lang === 'he'
                              ? '3vw'
                              : 0,
                 marginRight: lang === 'en'
                              ? '3vw'
                              : 0,
                 filter:      `drop-shadow(0 0 0.2rem ${generalTheme.primaryColor})`,
             }} />
        
        {/*menu*/}
        <Col span={12} >
            <Row justify='space-around' >
                <Col onClick={() => scrollTo('home')} >
                    <Styled.Item >{t('HeaderHome')}</Styled.Item >
                </Col >
                
                <Col onClick={() => scrollTo('features')} >
                    <Styled.Item >{t('HeaderFeatures')}</Styled.Item >
                </Col >
                
                <Col onClick={() => scrollTo('customers')} >
                    <Styled.Item >{t('HeaderCustomers')}</Styled.Item >
                </Col >
                
                <Col onClick={() => scrollTo('about')} >
                    <Styled.Item >{t('HeaderAbout')}</Styled.Item >
                </Col >
                
                <Col onClick={() => scrollTo('faq')} >
                    <Styled.Item >{t('HeaderFaq')}</Styled.Item >
                </Col >
            </Row >
        </Col >
        
        {/*language*/}
        <Row justify={'space-between'}
             style={{ width: 70, margin: '0 50px' }} >
            <Col >
                <Styled.LanguageSwitch onClick={() => {
                    changeLangAction('he');
                    i18n.changeLanguage('he');
                }} >
                    <img src={israel}
                         aria-label='homepage'
                         width='30px'
                         height='30px'
                         alt={'israel'} />
                </Styled.LanguageSwitch >
            </Col >
            
            <Col >
                <Styled.LanguageSwitch onClick={() => {
                    changeLangAction('en');
                    i18n.changeLanguage('en');
                }} >
                    <img src={usa}
                         aria-label='homepage'
                         width='30px'
                         height='30px'
                         alt={'usa'} />
                </Styled.LanguageSwitch >
            </Col >
        </Row >
    </Styled.RowContainer >
    );
};

const mapStateToProps = (state: any) => {
    return {
        theme: state.preferences.theme,
        lang:  state.preferences.lang,
    };
};

const mapDispatchToProps = {
    changeLangAction,
    changeThemeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HorizontalMenu));
