import { withTranslation } from 'react-i18next';
import { InputProps } from '../../../@types/types';
import * as Styled from './TextArea.Styled';

const TextArea = ({ id, name, placeholder, onChange, value, t }: InputProps) => (
<Styled.Container >
    <Styled.Label htmlFor={name} >{t(name)}</Styled.Label >
    <Styled.TextArea placeholder={t(placeholder)}
                     id={id}
                     name={name}
                     value={value}
                     onChange={onChange} />
</Styled.Container >
);

export default withTranslation()(TextArea);
