import { Col, Collapse, Row } from 'antd';
import { CSSProperties, FC } from 'react';
import { withTranslation } from 'react-i18next';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { IFaq, Lang } from '../../@types/types';
import faqSvg from '../../assets/faq.svg';
import engJson from '../../locales/en/translation.json';
import hebJson from '../../locales/he/translation.json';
import { darkTheme, DixiTheme, generalTheme, lightTheme } from '../../theme/theme';
import * as GlobalStyled from '../styled/Global.styled';
import { imgPosAndShadow } from '../styled/Global.styled';

interface IProps {
    lang: Lang;
    t: any;
    theme: DixiTheme;
}

const Faq: FC<IProps> = ({ t, lang, theme }) => {
    
    const faq: IFaq[] = lang === 'he'
    ? hebJson.Faq
    : engJson.Faq;
    const renderedFaq = faq.map((faq) => (
    <Collapse.Panel key={uuidv4()}
                    header={faq.question}
                    style={faqStyleObject} >
        <div style={{
            padding:    '0 47px',
            fontSize:   18,
            fontWeight: 600,
            color:      theme === 'light'
                        ? lightTheme.faqAnswer
                        : darkTheme.faqAnswer,
        }} >
            {faq.answer}
        </div >
    </Collapse.Panel >
    ),
    );
    
    return (
    <GlobalStyled.SectionContainer id={'faq'} >
        <Row align={'middle'}
             justify='space-between'
             gutter={100} >
            <Col lg={12}
                 md={24}
                 sm={24}
                 xs={24} >
                <GlobalStyled.SectionTitleCenter style={{
                    textAlign: lang === 'he'
                               ? 'right'
                               : 'left', margin: ' 0 20px',
                }} >{t('FaqTitle')}</GlobalStyled.SectionTitleCenter >
                
                <Collapse
                accordion
                ghost
                expandIcon={({ isActive }) => isActive
                ? <FiMinus style={{ margin: '0 10px' }}
                           color={'#6E6E6E'}
                           size={14} />
                : <FiPlus style={{ margin: '0 10px' }}
                          color={'#6E6E6E'}
                          size={14} />} >
                    {renderedFaq}
                </Collapse >
            </Col >
            
            <Col lg={12}
                 md={24}
                 sm={24}
                 xs={24} >
                <img src={faqSvg}
                     width={'65%'}
                     alt={'faq'}
                     style={{ ...imgPosAndShadow, ...{ transform: 'translateY(50px)' } }} />
            </Col >
        </Row >
    
    </GlobalStyled.SectionContainer >
    );
};

const mapStateToProps = (state: any) => {
    return {
        theme: state.preferences.theme,
        lang:  state.preferences.lang,
    };
};

export default connect(mapStateToProps)(withTranslation()(Faq));

const faqStyleObject: CSSProperties = {
    borderBottom: `1px solid ${generalTheme.primaryColor}`,
};
