import { Lang, Theme } from '../../@types/types';

export const CHANGE_LANG = 'CHANGE_LANG';
export const CHANGE_THEME = 'CHANGE_THEME';

export const changeLangAction = (lang: Lang) => {
    return {
        type:    CHANGE_LANG,
        payload: lang,
    };
};

export const changeThemeAction = (theme: Theme) => {
    return {
        type:    CHANGE_THEME,
        payload: theme,
    };
};
