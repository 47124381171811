import { Lang, Theme } from '../../@types/types';
import { CHANGE_LANG, CHANGE_THEME } from '../actions/preferencesActions';

type IActions = typeof CHANGE_LANG | typeof CHANGE_THEME;

interface IAction {
    payload: any,
    type: IActions
}

interface IState {
    lang: Lang,
    theme: Theme,
}

const initialState: IState = {
    lang:  'he',
    theme: 'dark',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: IAction) {
    switch (action.type) {
        case CHANGE_LANG:
            return {
                ...state,
                lang: action.payload,
            };
        
        case CHANGE_THEME:
            return {
                ...state,
                theme: action.payload,
            };
        
        default:
            return state;
    }
}
