import { ConfigProvider } from 'antd';
import { FC } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Lang, Theme } from '../../@types/types';
import Landing from '../../pages/Landing';
import { GlobalStyle } from '../../styles/globalStyle';
import { darkTheme, lightTheme } from '../../theme/theme';
import Footer from '../footer/footer';
import Header from '../header/Header';
import { Container } from './App.Styled';

interface IProps {
    lang: Lang;
    theme: Theme;
}

const App: FC<IProps> = ({ theme, lang }) => {
    
    return (
    <ThemeProvider theme={theme === 'light'
    ? lightTheme
    : darkTheme} >
        <GlobalStyle />
        <ConfigProvider direction={lang === 'he'
        ? 'rtl'
        : 'ltr'} >
            <Container >
                <div id={'top'} />
                <Header />
                <Landing />
                <Footer />
            </Container >
        </ConfigProvider >
    </ThemeProvider >
    );
};

const mapStateToProps = (state: any) => {
    return {
        theme: state.preferences.theme,
        lang:  state.preferences.lang,
    };
};

export default connect(mapStateToProps)(App);
