import { Col, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Lang } from '../../@types/types';
import logo from '../../assets/dixi-logo-new.svg';
import homeSvg from '../../assets/home2.svg';
import { generalTheme } from '../../theme/theme';
import { Button } from '../common/button/Button';
import * as GlobalStyled from '../styled/Global.styled';
import { imgPosAndShadow } from '../styled/Global.styled';
import * as Styled from './Home.Styled';
import { HomeImage } from './Home.Styled';

interface IProps {
    lang: Lang,
    t: any;
}

const Home: FC<IProps> = ({ t }) => {
    
    const scrollTo = (id: string) => {
        const element = document.getElementById(id) as HTMLDivElement;
        element.scrollIntoView({
            behavior: 'smooth',
            block:    'center',
        });
    };
    
    const [width, setWidth] = useState(0);
    
    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        
    }, [width]);
    
    const updateWindowDimensions = () => {
        setWidth(window.innerWidth);
    };
    
    return (
    <GlobalStyled.SectionContainer id={'home'} >
        
        <Row justify={'center'}
             style={{ transform: 'translateY(-50px)' }} >
            <Col >
                {width <= 990 && <img alt={'logo'}
									  width={220}
									  src={logo}
									  style={{
                                          marginBottom: 20,
                                          filter:       `drop-shadow(0 0 0.4rem ${generalTheme.primaryColor})`,
                                      }} />}
            </Col >
        </Row >
        
        <Row justify='space-between'
             align='middle' >
            <Col lg={12}
                 md={24}
                 sm={24}
                 xs={24}
                 push={1} >
                <GlobalStyled.SectionTitleAligned >{t('HomeTitle')}</GlobalStyled.SectionTitleAligned >
                <GlobalStyled.SectionTextAligned >{t('HomeText')}</GlobalStyled.SectionTextAligned >
                
                <Styled.ButtonWrapper >
                    <Button key={'home' + t('HomeButtonAbout')}
                            fixedWidth={true}
                            onClick={() => scrollTo('about')} >
                        {t('HomeButtonAbout')}
                    </Button >
                    
                    <Button key={'home' + t('HomeButtonContact')}
                            color={'#EAEAEA'}
                            fixedWidth={true}
                            onClick={() => scrollTo('contact')} >
                        {t('HomeButtonContact')}
                    </Button >
                </Styled.ButtonWrapper >
            </Col >
            
            <Col lg={12}
                 md={24}
                 sm={24}
                 xs={24} >
                <HomeImage src={homeSvg}
                           width={450}
                           alt='test'
                           style={imgPosAndShadow} />
            </Col >
        </Row >
    </GlobalStyled.SectionContainer >
    );
};

const mapStateToProps = (state: any) => {
    return {
        lang: state.preferences.lang,
    };
};

export default connect(mapStateToProps)(withTranslation()(Home));
