import { Col, Row } from 'antd';
import { FC } from 'react';
import { withTranslation } from 'react-i18next';
import about from '../../assets/about.svg';
import * as GlobalStyled from '../styled/Global.styled';
import { imgPosAndShadow } from '../styled/Global.styled';

interface IProps {
    t: any;
}

const About: FC<IProps> = ({ t }) => {
    
    return (
    <GlobalStyled.SectionContainer id={'about'} >
        <Row justify='space-between'
             align='middle' >
            <Col lg={12}
                 md={24}
                 sm={12}
                 xs={24} >
                <img src={about}
                     width={'65%'}
                     alt='test'
                     style={imgPosAndShadow} />
            
            </Col >
            
            <Col lg={12}
                 md={24}
                 sm={24}
                 xs={24} >
                <GlobalStyled.SectionTitleAligned >{t('AboutTitle')}</GlobalStyled.SectionTitleAligned >
                <GlobalStyled.SectionTextAligned >{t('AboutText')}</GlobalStyled.SectionTextAligned >
            </Col >
        </Row >
    </GlobalStyled.SectionContainer >
    );
};

export default withTranslation()(About);
