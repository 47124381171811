import {
    EnvironmentOutlined,
    LinkedinOutlined,
    PhoneOutlined,
} from '@ant-design/icons';
import { Button as ButtonAntd, Col, Row } from 'antd';
import { FC } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Lang, Theme } from '../../@types/types';
import { SectionContainer } from '../styled/Global.styled';
import * as Styled from './footer.Styled';

interface IProps {
    t: any;
    lang?: Lang;
    theme?: Theme;
}

const Footer: FC<IProps> = ({ t, theme }) => {
    return (
        <SectionContainer>
            <Styled.Extra>
                <Row
                    justify='start'
                    align='middle'
                    style={{
                        paddingTop: 40,
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    }}
                >
                    {/*ADDRESS*/}
                    <Col span={8} style={{ color: '#C7C7C8' }}>
                        <Row>
                            <Col>
                                <EnvironmentOutlined
                                    style={{ color: '#C7C7C8', fontSize: 24 }}
                                />
                            </Col>
                            <Col
                                style={{
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}
                            >
                                <Styled.Para>{t('FooterCity')}</Styled.Para>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={8} offset={8} style={{ color: '#C7C7C8' }}>
                        <Row justify={'end'} align={'middle'}>
                            {/*PHONE*/}
                            <Col
                                onClick={() => {
                                    window.location.href = `tel:${'+'}`;
                                }}
                                style={{ cursor: 'pointer' }}
                                dir={'ltr'}
                            >
                                {''}
                            </Col>

                            <Col
                                onClick={() => {
                                    window.location.href = `tel:${'+'}`;
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <ButtonAntd
                                    style={{}}
                                    type='text'
                                    icon={
                                        <PhoneOutlined
                                            style={{
                                                color: '#C7C7C8',
                                                fontSize: 24,
                                                transform: 'scaleX(-1)',
                                            }}
                                        />
                                    }
                                ></ButtonAntd>
                            </Col>
                            {/*LINKEDIN*/}
                            <Col>
                                <ButtonAntd
                                    style={{}}
                                    type='text'
                                    icon={
                                        <LinkedinOutlined
                                            style={{
                                                color: '#C7C7C8',
                                                fontSize: 24,
                                            }}
                                        />
                                    }
                                    onClick={() => {
                                        const linkUrl =
                                            'https://www.linkedin.com/company/dixilang/';
                                        window.open(linkUrl, '_blank');
                                    }}
                                ></ButtonAntd>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Styled.Extra>
        </SectionContainer>
    );
};

const mapStateToProps = (state: any) => {
    return {
        theme: state.preferences.theme,
        lang: state.preferences.lang,
    };
};

export default connect(mapStateToProps)(withTranslation()(Footer));
